import React from "react"


const EmbedMap = ({ url, title }) => {

    const api_key = process.env.GATSBY_GCP_API_KEY

    return (
        <div className="flex justify-around content-center">
            <iframe title={title}  className="p-4 h-96 w-full" loading="lazy" frameborder="0" style={{border:0}} allowfullscreen aria-hidden="false" src={url + "&key=" + api_key} />
        </div>
    )
}

export default EmbedMap;