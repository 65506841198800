import React from "react"

const PairTable = ({ values }) => {
    const rows = values.map((item) =>
        <tr className="border-b lg:flex">
            <th className="block text-left px-4 py-5 bg-secondary text-primary font-bold lg:w-40">{item[0]}</th>
            <td className="block px-4 py-5 bg-white text-black whitespace-pre-wrap">{item[1]}</td>
        </tr>
    );

    return (
        <table className="rounded-t-lg w-full border">
            {rows}
        </table>
    )
}

export default PairTable;